exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-customer-support-js": () => import("./../../../src/pages/careers/customer-support.js" /* webpackChunkName: "component---src-pages-careers-customer-support-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-sales-js": () => import("./../../../src/pages/careers/sales.js" /* webpackChunkName: "component---src-pages-careers-sales-js" */),
  "component---src-pages-careers-userbrain-tester-deutsch-js": () => import("./../../../src/pages/careers/userbrain-tester-deutsch.js" /* webpackChunkName: "component---src-pages-careers-userbrain-tester-deutsch-js" */),
  "component---src-pages-careers-userbrain-tester-english-js": () => import("./../../../src/pages/careers/userbrain-tester-english.js" /* webpackChunkName: "component---src-pages-careers-userbrain-tester-english-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-compare-trymata-js": () => import("./../../../src/pages/compare/trymata.js" /* webpackChunkName: "component---src-pages-compare-trymata-js" */),
  "component---src-pages-compare-trymyui-js": () => import("./../../../src/pages/compare/trymyui.js" /* webpackChunkName: "component---src-pages-compare-trymyui-js" */),
  "component---src-pages-compare-userfeel-js": () => import("./../../../src/pages/compare/userfeel.js" /* webpackChunkName: "component---src-pages-compare-userfeel-js" */),
  "component---src-pages-compare-userlytics-js": () => import("./../../../src/pages/compare/userlytics.js" /* webpackChunkName: "component---src-pages-compare-userlytics-js" */),
  "component---src-pages-compare-usertesting-js": () => import("./../../../src/pages/compare/usertesting.js" /* webpackChunkName: "component---src-pages-compare-usertesting-js" */),
  "component---src-pages-compare-userzoom-js": () => import("./../../../src/pages/compare/userzoom.js" /* webpackChunkName: "component---src-pages-compare-userzoom-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-features-ai-insights-js": () => import("./../../../src/pages/features/ai-insights.js" /* webpackChunkName: "component---src-pages-features-ai-insights-js" */),
  "component---src-pages-features-automated-reports-js": () => import("./../../../src/pages/features/automated-reports.js" /* webpackChunkName: "component---src-pages-features-automated-reports-js" */),
  "component---src-pages-features-collaborate-js": () => import("./../../../src/pages/features/collaborate.js" /* webpackChunkName: "component---src-pages-features-collaborate-js" */),
  "component---src-pages-features-easy-setup-js": () => import("./../../../src/pages/features/easy-setup.js" /* webpackChunkName: "component---src-pages-features-easy-setup-js" */),
  "component---src-pages-features-invite-your-own-testers-js": () => import("./../../../src/pages/features/invite-your-own-testers.js" /* webpackChunkName: "component---src-pages-features-invite-your-own-testers-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-tester-pool-js": () => import("./../../../src/pages/features/tester-pool.js" /* webpackChunkName: "component---src-pages-features-tester-pool-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-hub-index-js": () => import("./../../../src/pages/press-hub/index.js" /* webpackChunkName: "component---src-pages-press-hub-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-guerilla-usability-testing-cheat-sheet-js": () => import("./../../../src/pages/resources/guerilla-usability-testing-cheat-sheet.js" /* webpackChunkName: "component---src-pages-resources-guerilla-usability-testing-cheat-sheet-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-terms-de-js": () => import("./../../../src/pages/terms-de.js" /* webpackChunkName: "component---src-pages-terms-de-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tester-terms-de-js": () => import("./../../../src/pages/tester-terms-de.js" /* webpackChunkName: "component---src-pages-tester-terms-de-js" */),
  "component---src-pages-tester-terms-js": () => import("./../../../src/pages/tester-terms.js" /* webpackChunkName: "component---src-pages-tester-terms-js" */),
  "component---src-pages-user-testing-adobexd-prototype-js": () => import("./../../../src/pages/user-testing/adobexd-prototype.js" /* webpackChunkName: "component---src-pages-user-testing-adobexd-prototype-js" */),
  "component---src-pages-user-testing-axure-prototype-js": () => import("./../../../src/pages/user-testing/axure-prototype.js" /* webpackChunkName: "component---src-pages-user-testing-axure-prototype-js" */),
  "component---src-pages-user-testing-figma-prototype-js": () => import("./../../../src/pages/user-testing/figma-prototype.js" /* webpackChunkName: "component---src-pages-user-testing-figma-prototype-js" */),
  "component---src-pages-user-testing-framer-prototype-js": () => import("./../../../src/pages/user-testing/framer-prototype.js" /* webpackChunkName: "component---src-pages-user-testing-framer-prototype-js" */),
  "component---src-pages-user-testing-invision-prototype-js": () => import("./../../../src/pages/user-testing/invision-prototype.js" /* webpackChunkName: "component---src-pages-user-testing-invision-prototype-js" */),
  "component---src-pages-user-testing-prototypes-js": () => import("./../../../src/pages/user-testing/prototypes.js" /* webpackChunkName: "component---src-pages-user-testing-prototypes-js" */),
  "component---src-pages-user-testing-sketch-prototype-js": () => import("./../../../src/pages/user-testing/sketch-prototype.js" /* webpackChunkName: "component---src-pages-user-testing-sketch-prototype-js" */),
  "component---src-pages-user-testing-template-library-js": () => import("./../../../src/pages/user-testing-template-library.js" /* webpackChunkName: "component---src-pages-user-testing-template-library-js" */),
  "component---src-pages-user-testing-templates-index-js": () => import("./../../../src/pages/user-testing-templates/index.js" /* webpackChunkName: "component---src-pages-user-testing-templates-index-js" */),
  "component---src-pages-webinar-registration-confirmation-js": () => import("./../../../src/pages/webinar-registration/confirmation.js" /* webpackChunkName: "component---src-pages-webinar-registration-confirmation-js" */),
  "component---src-pages-webinar-registration-index-js": () => import("./../../../src/pages/webinar-registration/index.js" /* webpackChunkName: "component---src-pages-webinar-registration-index-js" */),
  "component---src-templates-customer-success-story-index-js": () => import("./../../../src/templates/CustomerSuccessStory/index.js" /* webpackChunkName: "component---src-templates-customer-success-story-index-js" */),
  "component---src-templates-example-category-index-js": () => import("./../../../src/templates/ExampleCategory/index.js" /* webpackChunkName: "component---src-templates-example-category-index-js" */),
  "component---src-templates-example-device-index-js": () => import("./../../../src/templates/ExampleDevice/index.js" /* webpackChunkName: "component---src-templates-example-device-index-js" */),
  "component---src-templates-example-index-js": () => import("./../../../src/templates/Example/index.js" /* webpackChunkName: "component---src-templates-example-index-js" */),
  "component---src-templates-example-task-type-index-js": () => import("./../../../src/templates/ExampleTaskType/index.js" /* webpackChunkName: "component---src-templates-example-task-type-index-js" */),
  "component---src-templates-example-type-index-js": () => import("./../../../src/templates/ExampleType/index.js" /* webpackChunkName: "component---src-templates-example-type-index-js" */),
  "component---src-templates-template-category-page-index-js": () => import("./../../../src/templates/TemplateCategoryPage/index.js" /* webpackChunkName: "component---src-templates-template-category-page-index-js" */)
}

